import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";

const Container = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #111;
  align-items: center;
  margin-top: 100px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const RowTitle = styled.div`
  font-size: 17px;
  font-weight: 900;
`;

let variant = "minimal-underlined";

export default function Footer() {
  return (
    <Container>
      <Row>Copyright © 2023 Arnav Singh</Row>
    </Container>
  );
}
