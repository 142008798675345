import styled from "styled-components";
import ProjectCard from "./ProjectCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import TestimonialCard from "./TestimonialCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
`;

export default function Testimonials({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  return (
    <Container ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>TESTIMONIALS</Title>
          <Description>Our Student Testimonials</Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Maya Shivani, Capgemini"
          >
            Data Science - we all have heard about it enough to know that this
            is one of the leading programmes. It is a vast domain which I had
            the ‘opportunity to learn from ACMEGRADE. It has not only benefited
            me with the skills for developing algorithms and understanding data
            but also gave me an early start into making this my expertise. Today
            | prepare for my masters by having known what I want to do with my
            life and I thank ACMEGRADE for that.
          </TestimonialCard>
        </List>
      </motion.div>
    </Container>
  );
}
