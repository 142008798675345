import styled from "styled-components";

import LogoSVG from "./LogoSVG.js";
import Link from "../helperComponents/Link.js";

const BrandContainerMain = styled.img`
  height: 80px;
  width: auto;

  @media (max-width: 900px) {
    height: 40px;
  }
`;

const H1 = styled.h1`
  font-weight: 500;
  margin: 0;
  margin-top: -3px;
  font-size: 30px;
  width: auto;
  font-family: "Raleway";
  /* width: 100%; */
`;

const Row = styled.div``;

export default function BrandContainer({ style }) {
  return (
    <Link href="/">
      <BrandContainerMain style={style} src="/full-logo.png" />
    </Link>
  );
}
