import styled from "styled-components";
import ProjectCard from "./ProjectCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import ProjectCardBig from "./ProjectCardBig";
import BlogCard from "./BlogCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;

  @media (max-width: 900px) {
    width: 80vw;
  }
`;

const List = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  width: 62vw;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    width: 90vw;
    flex-direction: column;
  }
`;

let blogs = [
  {
    title: "Understand Indexing in MongoDB",
    description:
      "The idea of indexing in MongoDB is similar to the index of any book, They increase the speed of finding a page",
    link: "https://medium.com/p/68a3e072ad00",
  },
  {
    title: "Understanding NGNIX",
    description:
      "NGNIX (pronounced Engine X) is a web server /reverse proxy/load balancer used by many high traffic applications",
    link: "https://medium.com/p/e1fd420cbe70",
  },
  {
    title: "Deploy Node.js on ubuntu with GIT",
    description: "Short and fast notes for setting up a serve",
    link: "r",
  },
  {
    title: "How Does SSL Work? ",
    description:
      "What is TLS security? Digital certificates, Asymmetric Encryption, SSL vs TLS Explained",
    link: "https://medium.com/p/5e33d27ce086",
  },
  {
    title: "All the things failure taught me.",
    description:
      "People around you are going to give you good reasons to do the wrong thing, thinking in terms of consequences, can provide a safe judgment",
    link: "https://medium.com/p/4a88f1a65851",
  },
  {
    title: "Github CheatSheet",
    description:
      "A simple guide that provides a concise overview of crucial Git operations, including managing branches...",
    link: "https://medium.com/p/fed00ec18467",
  },
  {
    title: "Integration Simplified",
    description:
      "Integration is just formulas of area of the graph for different curves. which people found by different methods curated under one name",
    link: "https://medium.com/p/70871261d8d3",
  },
  {
    title: "Neural Networks (a simple explanation)",
    description:
      "Neural networks are all about finding a pattern or in other words weights are the patterns..",
    link: "https://arnavzek.medium.com/neural-network-is-simple-as-dumb-68c229f69532",
  },
];

export default function MyBlog({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.4 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.3 1"],
  });

  return (
    <Container id="blog-section" ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>Blogs</Title>
          <Description>
            Blogging helps me think and learn new things. It is always a joy.
          </Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          {blogs.map((item) => (
            <BlogCard
              key={item.link}
              title={item.title}
              description={item.description}
              link={item.link}
            />
          ))}
        </List>
      </motion.div>
    </Container>
  );
}
