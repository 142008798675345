import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";

const Container = styled.div`
  width: 30vw;
  background: var(--glassGradient);
  color: #fff;
  gap: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  height: 27vw;
  object-fit: cover;
  border-radius: 10px;
`;

const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 19px;
  font-family: "Dela Gothic One", sans-serif;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 16px;
  opacity: 0.7;

  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

const Tagline = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 19px;
  font-family: urbanist;
`;

const Buttons = styled.div`
  margin-top: 30px;
`;

export default function BlogCard({
  title,
  description,
  link,

  style,
  technologies,
}) {
  if (!style) style = {};

  return (
    <Container onClick={goTo(link)} style={{ ...style }}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}
