import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";

const Container = styled.div`
  width: 100%;
  gap: 50px;
  background-color: var(--mainGradient);
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 40%;
  border-radius: 10px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "Dela Gothic One", sans-serif;
`;

const Tagline = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 19px;
  font-family: urbanist;
`;

const Description = styled.div`
  width: 100%;
  font-size: 19px;
  opacity: 0.7;
`;

const Buttons = styled.div`
  margin-top: 40px;
`;
export default function ProjectCardBig({
  image,
  title,
  tagline,
  link,
  technologies,
  description,
  gradient,
  filter,
  style,
}) {
  if (!style) style = {};

  return (
    <Container style={{ background: gradient, ...style }}>
      <Image style={{ filter }} src={`/projects/${image}`} />

      <Text>
        <Title>{title}</Title>
        <Tagline>{tagline}</Tagline>
        <Description>{description}</Description>
        <Description>{technologies}</Description>
        <Buttons>
          <CustomButton style={{ width: "200px" }} onClick={goTo(link)}>
            Open Website
          </CustomButton>
        </Buttons>
      </Text>
    </Container>
  );
}
