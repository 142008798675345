import styled from "styled-components";
import MaterialInput from "../../helperComponents/MaterialInput";
import CustomButton from "../../helperComponents/CustomButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 30vw;
  z-index: 555;

  @media (max-width: 900px) {
    width: 90vw;
    gap: 30px;
  }
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30vw;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 65px;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 25px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Button = styled.button`
  padding: 15px 20px;
  background: var(--mainGradient);
  color: var(--bgColor);
  border-radius: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;
  /* transition: 0.1s ease-in all; */
  cursor: pointer;

  transition: all 0.25s ease-in-out;
  &:hover {
    transform: scale(0.95);
  }
`;

const Description = styled.div`
  opacity: 0.7;
  font-size: 18px;
`;

export default function ContactForm() {
  return (
    <Container>
      <ContainerText>
        <Tagline>Get in touch</Tagline>

        <Description>
          You can start with a Hi, who knows, maybe we will make something
          interesting together.
        </Description>
      </ContainerText>

      <Form action="https://formspree.io/f/xpwarjwk" method="POST">
        <MaterialInput
          label="Your Email"
          variant="filled"
          placeholder={"Type your email here"}
          type="email"
          name="email"
        />

        <MaterialInput
          placeholder={"Your Message"}
          multiline={true}
          rows={4}
          label="Type your message here."
          name="message"
        />
        <Button type="submit">Send</Button>
      </Form>
    </Container>
  );
}
