import styled from "styled-components";

import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import AlumniCompanyCard from "./AlumniCompanyCard";

import {
  FaAmazon,
  FaAngular,
  FaFacebook,
  FaGoogle,
  FaLaravel,
  FaMeta,
  FaMicrosoft,
  FaNodeJs,
  FaReact,
  FaUnity,
} from "react-icons/fa6";
import {
  BsApple,
  BsGitlab,
  BsReddit,
  BsSlack,
  BsSnapchat,
  BsTwitch,
  BsTwitter,
  BsUnity,
} from "react-icons/bs";
import { GrGithub } from "react-icons/gr";
import { PiPinterestLogo } from "react-icons/pi";
import { MdFlutterDash } from "react-icons/md";
import { RiNextjsFill } from "react-icons/ri";
import { BiLogoFlutter } from "react-icons/bi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;
  width: 40vw;
  line-height: 1.7;
  font-family: urbanist;

  @media (max-width: 900px) {
    width: 80vw;
  }
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default function MySkills({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  return (
    <Container id="skills-section" ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>My Skills</Title>
          <Description>
            I have been freelancing for more than 5 years, this have given me
            the opportunity to work with a lot of different technologies and
            teams.
          </Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          <AlumniCompanyCard>
            <FaReact />
          </AlumniCompanyCard>
          <AlumniCompanyCard>
            <FaAngular />
          </AlumniCompanyCard>
          <AlumniCompanyCard>
            <BsUnity />
          </AlumniCompanyCard>
          <AlumniCompanyCard>
            <BiLogoFlutter />
          </AlumniCompanyCard>
          <AlumniCompanyCard>
            <RiNextjsFill />
          </AlumniCompanyCard>
          <AlumniCompanyCard>
            <FaNodeJs />
          </AlumniCompanyCard>
        </List>
      </motion.div>
    </Container>
  );
}
