import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import Context from "../../../Context";

let canvasDimension = 1000;

const Canvas = styled.canvas`
  margin-left: 0;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

let maxFrames = 40;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  justify-content: flex-end;
`;

export default function ContactMe({ containerRef }) {
  const { isMobile } = useContext(Context);

  const ref = useRef(null);
  const targetRef = useRef(null);

  const warriorAnimation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const textAnimation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.1 1", "0.4 1"],
  });

  const textHozAnimation = useTransform(
    textAnimation.scrollYProgress,
    [0, 1],
    [-300, 0]
  );

  const images = useMemo(() => {
    const loadedImages = [];

    for (let i = 1; i <= maxFrames; i++) {
      const img = new Image();
      img.src = `/glasses/${addZeros(i)}.jpg`;
      console.log(img.src);
      loadedImages.push(img);
    }

    return loadedImages;
  }, []);

  const render = useCallback(
    (index) => {
      let context = ref.current?.getContext("2d");
      let theImage = images[index - 1];

      if (context) {
        context.canvas.width = canvasDimension;
        context.canvas.height = canvasDimension;
        if (theImage)
          context.drawImage(theImage, 0, 0, canvasDimension, canvasDimension);
      }
    },
    [images]
  );

  useEffect(() => {
    let context = ref.current?.getContext("2d");

    if (context) {
      context.canvas.width = window.innerWidth;
      context.canvas.height = window.innerHeight;
    }
  }, [ref]);

  const currentIndex = useTransform(
    warriorAnimation.scrollYProgress,
    [0, 1],
    [1, maxFrames]
  );

  useMotionValueEvent(currentIndex, "change", (latest) => {
    render(Number(latest.toFixed()));
  });

  useEffect(() => {
    render(1);
  }, [render]);

  console.log("aaaaaa", textAnimation.scrollYProgress);

  if (!isMobile)
    return (
      <Container
        id="contact-section"
        ref={targetRef}
        style={{
          height: "6000px",
          backgroundColor: "black",
          display: "flex",
        }}
      >
        {" "}
        <Sticky>
          <Row>
            <motion.div
              style={{
                scale: textAnimation.scrollYProgress,
                x: textHozAnimation,
                position: "absolute",
                top: "200px",
                left: "400px",
              }}
            >
              <ContactForm />
            </motion.div>

            <Canvas
              style={{
                width: canvasDimension + "px",
                height: canvasDimension + "px",
              }}
              ref={ref}
            />
          </Row>
        </Sticky>{" "}
      </Container>
    );

  return (
    <Container
      id="contact-section"
      ref={targetRef}
      style={{
        display: "flex",
      }}
    >
      <Sticky>
        <motion.div
          style={{
            scale: textAnimation.scrollYProgress,
            x: textHozAnimation,
          }}
        >
          <ContactForm />
        </motion.div>
      </Sticky>
    </Container>
  );
}

function addZeros(str) {
  str = str.toString();

  if (str.length == 1) return `000${str}`;
  if (str.length == 2) return `00${str}`;
  if (str.length == 3) return `0${str}`;
  if (str.length == 4) return str;
}
