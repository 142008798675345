import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";

const Container = styled.div`
  width: 30vw;
  background-color: var(--mainGradient);
  color: #fff;
  gap: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  height: 27vw;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 900px) {
    height: 90vw;
  }
`;

const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 10px;
  font-family: "Dela Gothic One", sans-serif;
`;

const Description = styled.div`
  width: 100%;
  font-size: 19px;
  opacity: 0.7;
`;

const Tagline = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 19px;
  font-family: urbanist;
`;

const Buttons = styled.div`
  margin-top: 30px;
`;

export default function ProjectCard({
  image,
  title,
  tagline,
  link,
  description,
  gradient,
  filter,
  style,
  technologies,
}) {
  if (!style) style = {};

  return (
    <Container style={{ background: gradient, ...style }}>
      <Image style={{ filter }} src={`/projects/${image}`} />

      <Text>
        <Title>{title}</Title> <Tagline>{tagline}</Tagline>
        <Description>{description}</Description>
        <Description>{technologies}</Description>
        <Buttons>
          <CustomButton style={{ width: "200px" }} onClick={goTo(link)}>
            Open Website
          </CustomButton>
        </Buttons>
      </Text>
    </Container>
  );
}
