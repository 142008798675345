import { useEffect, useReducer, useState } from "react";
import reducer from "../../../reducer.js";
import Context from "../../../Context.js";
import DynamicForm from "../../helperComponents/dynamicForm/DynamicForm.js";
import getUserID from "../../../controllers/getUserID.js";
import { RiGoogleFill } from "react-icons/ri";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { useNavigate } from "react-router-dom";
import { serverLine } from "../../../controllers/serverLine.js";
import logout from "../../../controllers/logout.js";
import isLoggedIn from "../../../controllers/isLoggedIn.js";

import AskBasicInfo from "../../onboarding/AskBasicInfo.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import goTo from "../../../controllers/goTo.js";

import updateRouteState from "../../../controllers/updateRouteState.js";

import WithBackground from "./WithBackground.js";

import YouAreBanned from "../../report/YouAreBanned.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import getHostName from "../../../controllers/getHostName.js";
import { ThemeProvider, createTheme } from "@mui/material";
import SetupChat from "../routes/SetupChat.js";

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [formData, setFormData] = useState(false);
  const [globalLikeStatus, setGlobalLikeStatus] = useState({});

  const navigate = useNavigate();
  const alert = (message) => {
    toast(message);
  };

  const [colorMode, setColorMode] = useState("LIGHT");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, {});
  const [notificationData, setNotificationData] = useState({
    general: 0,
  });
  let currentPath = window.location.pathname + window.location.search;

  const [currentRoute, setCurrentRoute] = useState(currentPath);

  let initialNonPopupRoute = currentPath;

  const [nonPopupRoute, setNonPopupRoute] = useState(initialNonPopupRoute);

  //chat start
  const [chatLoading, setChatLoading] = useState(true);
  const [selectedGroupData, setSelectedGroupData] = useState("LOADING");
  const [groups, setGroups] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [friendsLastSeenAt, setFriendsLastSeenAt] = useState(null);
  const [oppositeMemberLastTypedAt, setOppositeMemberLastTypedAt] =
    useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let isMobile = screenWidth < 900;

  window.groups = groups;
  window.setGroups = setGroups;
  window.selectedGroupData = selectedGroupData;
  window.setSelectedGroupData = setSelectedGroupData;

  //chat end

  window.setNonPopupRoute = setNonPopupRoute;

  window.setCurrentRoute = setCurrentRoute;

  window.setForm = setForm;
  window.navigate = navigate;
  window.doAlert = alert;
  window.loggedInUserID = loggedInUserID;
  window.loggedInUser = loggedInUser;

  window.updateLoggedInUser = updateLoggedInUser;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      updateNotificationData();

      //Notification will only contain banning data, no need to do pooling
      // if (window.notifInterval) {
      //   window.clearInterval(window.notifInterval);
      // }
      // window.notifInterval = window.setInterval(() => {
      //   updateNotificationData();
      // }, 30 * 1000);
    }
  }, [loggedInUserID]);

  useState(() => {
    let theFunc = (theEvent) => {
      let newURl = window.location.pathname + window.location.search;

      updateRouteState(newURl);
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  useEffect(() => {
    let userID = getUserID();

    if (userID) {
      setLoggedInUserID(userID);
      updateLoggedInUser();
    } else {
      setLoggedInUserID(false);
    }
  }, []);

  useEffect(() => {
    removeFormQuery();
    backFeatures();

    let data = {};
    dispatch({ type: "NEW_STATE", value: data });

    let newStatus = localStorage.getItem("arnav-singh-color-mode");
    if (!newStatus) newStatus = "LIGHT";

    if (!loggedInUserID) newStatus = "LIGHT";
    updateDarkMode(newStatus);
  }, [loggedInUserID]);

  if (isLoggedIn()) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      if (loggedInUser.banned) children = <YouAreBanned />;
      if (!loggedInUser.onboarding) children = <AskBasicInfo />;
      if (loggedInUser.onboarding)
        if (!loggedInUser.onboarding.basicInfoAdded)
          children = <AskBasicInfo />;
    }
  }

  // children = <AskBasicInfo />;

  const themeMUI = createTheme({
    palette: {
      mode: colorMode.toLowerCase(),
      primary: {
        main: "#e8eaf6",
      },
      secondary: {
        main: "#8bc34a",
      },
    },
  });

  return (
    <Context.Provider
      value={{
        globalLikeStatus,
        updateGlobalLikeStatus,
        nonPopupRoute,
        setNonPopupRoute,
        currentRoute,
        setLoggedInUser,
        promptLogin,
        loggedInUserID,
        state,
        popupAlert: alert,
        updateLoggedInUser: updateLoggedInUser,
        loggedInUser: loggedInUser,
        dispatch,
        updateDarkMode,
        colorMode,
        setForm: setForm,
        notificationData,
        updateNotificationData,
        isMobile,
        screenWidth,
        //Chat start
        groups,
        setGroups,
        selectedGroupData,
        setSelectedGroupData,
        friendsLastSeenAt,
        setFriendsLastSeenAt,
        isConnected,
        setIsConnected,
        chatLoading,
        setChatLoading,
        oppositeMemberLastTypedAt,
        setOppositeMemberLastTypedAt,
        //chat end
      }}
    >
      <ThemeProvider theme={themeMUI}>
        <DynamicForm setForm={setForm} data={formData} />

        <WithBackground> {children}</WithBackground>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </ThemeProvider>
    </Context.Provider>
  );

  function updateNotificationData() {
    console.log(loggedInUser);
    serverLine.get("/notification-count").then(setNotificationData);
  }

  function updateGlobalLikeStatus(items) {
    let oldStatus = { ...window.nonReactLikeStatus };

    for (let item of items) {
      oldStatus[item._id] = {
        likeStatus: item.likeStatus,
        likeCount: item.likeCount,
        dislikeCount: item.dislikeCount,
      };
    }

    window.nonReactLikeStatus = oldStatus;
    setGlobalLikeStatus(oldStatus);
    //This makes sure that between frequent react updates value is not lost
  }

  function updateDarkMode(newStatus) {
    setColorMode(newStatus);
    localStorage.setItem("arnav-singh-color-mode", newStatus);
    setColors(newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then(setLoggedInUser);
    } catch (e) {
      alert.show(e.message);
      console.log("First Fetch Error----------");
      logout();
    }
  }

  function setColors(newVal) {
    let lightTheme = {
      bgColor: "#f2f2f7", //from apple
      foreground: "#fff",
      bgColorDark: "#999999",
      bgColor2: "#ffffff",
      color: "#111111",
      accentColor: "#8c9fbd",
      translucentLight: "rgba(0,0,0,0.04)",
      translucent: "rgba(0,0,0,0.1)",
      translucentHard: "rgba(0,0,0,0.2)",
    };

    let darkTheme = {
      bgColor: "#000000 ",
      foreground: "#111111",
      bgColorDark: "#000000",
      bgColorLight: "#222222",
      bgColor2: "#000000",
      color: "#ffffff",
      accentColor: "#886cff",
      accentLight: "#886cff",
      accentVeryLight: "#886cff",
      translucentLight: "rgb(212 226 227 / 5%)",
      translucent: "rgb(212 226 227 / 15%)",
      translucentHard: "rgba(255,255,255,0.2)",

      mainGradient: "linear-gradient(24deg, #b54fcb, #4a2be0)",

      glassGradientHeader: "linear-gradient(122deg, #e1d1c5, #e0dacf, #efebe5)",
      foreground: "rgba(255,255,255,0.15)",
      glassGradientLight:
        "linear-gradient(122deg, rgb(255 255 255 / 3%), rgb(255 255 255 / 2%), rgb(255 255 255 / 1%))",
      glassGradient:
        "linear-gradient(122deg, rgb(255 255 255 / 8%), rgb(255 255 255 / 2%), rgb(255 255 255 / 1%))",
      glassGradientHard:
        "linear-gradient(122deg, rgb(255 255 255 / 4%), rgb(255 255 255 / 6%), rgb(255 255 255 / 14%))",
    };

    let colors = darkTheme;

    // if (newVal) {
    //   if (newVal === "DARK")
    //     colors = darkTheme
    // }

    document.documentElement.style.setProperty(
      "--accentVeryLight",
      colors.accentVeryLight
    );

    document.documentElement.style.setProperty(
      "--accentLight",
      colors.accentLight
    );

    document.documentElement.style.setProperty(
      "--mainGradient",
      colors.mainGradient
    );

    document.documentElement.style.setProperty(
      "--hardShadow",
      colors.hardShadow
    );

    document.documentElement.style.setProperty(
      "--glassGradientHard",
      colors.glassGradientHard
    );

    document.documentElement.style.setProperty(
      "--onHoverColor",
      colors.onHoverColor
    );

    document.documentElement.style.setProperty(
      "--lightShadow",
      colors.lightShadow
    );

    document.documentElement.style.setProperty(
      "--glassGradient",
      colors.glassGradient
    );

    document.documentElement.style.setProperty(
      "--translucentLight",
      colors.translucentLight
    );

    document.documentElement.style.setProperty(
      "--foreground",
      colors.foreground
    );

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--bgColorDark",
      colors.bgColorDark
    );

    document.documentElement.style.setProperty("--bgColor", colors.bgColor);
    document.documentElement.style.setProperty(
      "--bgColorLight",
      colors.bgColorLight
    );
    document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
    document.documentElement.style.setProperty("--color", colors.color);
    document.documentElement.style.setProperty(
      "--translucent",
      colors.translucent
    );
    document.documentElement.style.setProperty(
      "--translucentHard",
      colors.translucentHard
    );
  }

  function promptLogin() {
    setFormData({
      title: "Please Login",
      buttons: [
        {
          icon: <RiGoogleFill />,
          name: "Login / Signup",
          onClick: goToAuthScreen,
        },
      ],
    });
  }

  function backFeatures() {
    window.addEventListener("popstate", (event) => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    const formPage = getUrlQuery("formPage");
    if (!formPage) {
      setFormData(null);
    }
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParamsForm = new URLSearchParams(queryString);
    return urlParamsForm.get(field);
  }

  function setForm(data, noPathChange) {
    if (noPathChange) {
      return setFormData({ data, noPathChange });
    }

    let path = window.location.pathname;
    let queryObj = getUrlQueryObject();

    if (getUrlQuery("formPage") && !data) {
      closeForm();
    } else if (!getUrlQuery("formPage")) {
      queryObj.formPage = "true";

      let thePath = removeLastSlash(path) + queryObjToUrl(queryObj);

      goTo(thePath)();

      setFormData({ data, noPathChange });
    } else {
      setFormData({ data, noPathChange });
    }
  }

  function removeLastSlash(urlString) {
    if (urlString[urlString.length - 1] == "/") {
      return urlString.slice(0, urlString.length - 1);
    } else {
      return urlString;
    }
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }

  function getUrlQueryObject() {
    let data = {};
    let raw = window.location.search.replace(/\?/gi, "").replace(/\//gi, "");
    if (!raw) return data;
    raw = raw.split("&");
    for (let itm of raw) {
      if (!itm) continue;
      itm = itm.split("=");
      if (itm.length == 2) {
        if (itm[1].trim()) {
          data[itm[0]] = itm[1];
        }
      }
    }
    return data;
  }

  function removeFormQuery() {
    //this prevents bug
    //what bug?
    //if url already has form query then
    //on setFormData query won't be pushed

    if (getUrlQuery("formPage")) {
      let path = window.location.pathname;
      let queryObj = getUrlQueryObject();
      delete queryObj.formPage;
      let newPath = "/";

      let queryPath = queryObjToUrl(queryObj);

      if (queryPath == "/?") queryPath = "";

      if (path === "/") {
        newPath = queryPath;
      } else {
        if (path[path.length - 1] == "/" && queryPath[0] == "/") {
          queryPath = queryPath.slice(1, queryPath.length);
        }
        newPath = path + queryPath;
      }

      if (newPath[newPath.length - 1] == "/") {
        newPath = newPath.slice(0, newPath.length - 1);
      }

      console.log("newPath", newPath, queryPath);
      goTo(newPath, { isReplace: true })();
    }
  }

  function closeForm() {
    //sometimes it takes a few seconds to push the router
    //if user presses back before then user might go 2 pages back
    if (getUrlQuery("formPage")) {
      goTo(-1)();
    }
  }
}

export default Boilerplate;
