import { serverLine } from "./serverLine.js";

function logout() {
  serverLine.get("/logout").then(() => {
    localStorage.removeItem("arnav-singh-token");
    localStorage.removeItem("arnav-singh-userID");
    window.location = window.location.origin;
  });

  // serverLine.delete("/cookie").then(() => {
  // window.location = window.location.origin;
  // });
}

export default logout;
